'use client'

import React from 'react'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { Badge } from '@/components/ui/badge'
import Link from 'next/link'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import Header from '@/components/Header'
import Section from '@/components/Section'
;``
import Footer from '@/components/Footer'
import HomeHero from '@/components/HomeHero'
import ReferralWidget from '@/components/ReferralWidget'
import ROUTES from './routes'

export default function Home() {
  return (
    <>
      <Header />

      <HomeHero />

      <section className="my-20 px-8">
        <h2 className="text-xl md:text-3xl font-bold text-center">
          3,832 candidates on CareerParty have been referred to:
        </h2>
        <div className="mt-6 grid grid-cols-1 justify-items-center sm:grid-cols-2 gap-4 md:flex md:flex-row md:space-x-6">
          <img
            alt="Amazon"
            className="h-6"
            src="/images/companies/amazon.svg"
          />
          <img
            alt="google"
            className="h-6"
            src="/images/companies/google.svg"
          />
          <img alt="meta" className="h-6" src="/images/companies/meta.svg" />
          <img
            alt="microsoft"
            className="h-6"
            src="/images/companies/microsoft.svg"
          />
          <img
            alt="openai"
            className="h-6"
            src="/images/companies/openai.svg"
          />
          <img
            alt="airbnb"
            className="h-6"
            src="/images/companies/airbnb.svg"
          />
          <Badge variant="secondary">
            <span className="text-sm">and more...</span>
          </Badge>
        </div>
        <Button className="mt-4 mx-auto block" variant="ghost">
          Learn more about employee referrals
        </Button>
      </section>

      <Section altBg>
        <div className="text-center">
          <h2 className="text-3xl font-bold">
            What customers are saying about our professionals
          </h2>
          <p className="mt-2 text-sm">
            Our professionals were rated 4.9 out of 5 stars with 700+ reviews
          </p>
        </div>
        <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 gap-6">
          <Card>
            <CardContent className="space-y-4">
              <div className="flex items-center space-x-4">
                <Avatar>
                  <AvatarImage
                    src="/images/testimonials/efrain.jpg"
                    alt="Efrain Perloff"
                  />
                  <AvatarFallback>CN</AvatarFallback>
                </Avatar>
                <div>
                  <p className="text-sm font-medium">Efrain Perloff</p>
                  <p className="text-xs text-gray-500">
                    Software Engineer at Google
                  </p>
                </div>
              </div>
              <p className="text-sm">
                "The professional I worked with on CareerParty was incredibly
                helpful and knowledgeable. They provided valuable insights that
                helped me land my dream job at Google."
              </p>
              <div className="flex items-center space-x-2">
                <StarIcon className="h-4 w-4 text-yellow-500" />
                <StarIcon className="h-4 w-4 text-yellow-500" />
                <StarIcon className="h-4 w-4 text-yellow-500" />
                <StarIcon className="h-4 w-4 text-yellow-500" />
                <StarIcon className="h-4 w-4 text-yellow-500" />
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardContent className="space-y-4">
              <div className="flex items-center space-x-4">
                <Avatar>
                  <AvatarImage
                    src="/images/testimonials/craig.jpeg"
                    alt="Craig Kurrus"
                  />
                  <AvatarFallback>CN</AvatarFallback>
                </Avatar>
                <div>
                  <p className="text-sm font-medium">Craig Kurrus</p>
                  <p className="text-xs text-gray-500">
                    Product Manager at Microsoft
                  </p>
                </div>
              </div>
              <p className="text-sm">
                "I was hesitant to use CareerParty at first, but the
                professional I worked with was incredibly helpful and
                responsive. They helped me navigate the interview process and
                land a great role at Microsoft."
              </p>
              <div className="flex items-center space-x-2">
                <StarIcon className="h-4 w-4 text-yellow-500" />
                <StarIcon className="h-4 w-4 text-yellow-500" />
                <StarIcon className="h-4 w-4 text-yellow-500" />
                <StarIcon className="h-4 w-4 text-yellow-500" />
                <StarIcon className="h-4 w-4 text-yellow-500" />
              </div>
            </CardContent>
          </Card>
        </div>
      </Section>

      <Section>
        <h2 className="text-3xl font-bold text-center">How It Works</h2>

        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="grid max-w-2xl mx-auto">
            <div className="flex flex-row items-start relative">
              <div className="flex flex-col items-center mr-6">
                <div className="w-px opacity-0 h-full" />
                <div>
                  <div className="flex items-center justify-center w-8 h-8 text-xs font-medium bg-zinc-800 text-white rounded-full">
                    1
                  </div>
                </div>
                <div className="absolute z-[-1] w-px h-full bg-zinc-300" />
              </div>
              <div className="flex flex-col pb-6 sm:items-center sm:flex-row">
                <div>
                  <p className="text-xl font-semibold sm:text-base">
                    Post a request
                  </p>
                  <p className="text-sm text-gray-700">
                    Request a referral to your dream company.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-row items-start relative">
              <div className="flex flex-col items-center mr-6">
                <div className="w-px bg-zinc-300 h-full" />
                <div>
                  <div className="flex items-center justify-center w-8 h-8 text-xs font-medium bg-zinc-800 text-white rounded-full">
                    2
                  </div>
                </div>
                <div className="absolute z-[-1] w-px h-full bg-zinc-300" />
              </div>
              <div className="flex flex-col pb-6 sm:items-center sm:flex-row">
                <div>
                  <p className="text-xl font-semibold sm:text-base">
                    Professional fulfills your request
                  </p>
                  <p className="text-sm text-gray-700">
                    A verified professional on our platform accepts your request
                    and then completes it within 7 days.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-row items-start relative">
              <div className="flex flex-col items-center mr-6">
                <div className="w-px bg-zinc-300 h-full" />
                <div>
                  <div className="flex items-center justify-center w-8 h-8 text-xs font-medium bg-zinc-800 text-white rounded-full">
                    3
                  </div>
                </div>
                <div className="absolute z-[-10] w-px h-full opacity-0" />
              </div>
              <div className="flex flex-col pb-6 sm:items-center sm:flex-row">
                <div>
                  <p className="text-xl font-semibold sm:text-base">
                    Land your dream job!
                  </p>
                  <p className="text-sm text-gray-700">
                    Get fast-tracked and land your dream job!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section altBg>
        <h2 className="text-3xl font-bold text-center">
          Just completed on CareerParty
        </h2>
        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <Card className="w-full">
            <CardContent className="space-y-2">
              <p className="text-sm">Looking for a referral to Google</p>
              <p className="text-xs">
                1. My Background: Currently a Recruiting Coordinator with
                sourcing experience from...
              </p>
              <Badge variant="secondary">
                <img
                  alt="google"
                  className="h-3"
                  src="/images/companies/google.svg"
                />
              </Badge>
            </CardContent>
          </Card>
          <Card className="w-full">
            <CardContent className="space-y-2">
              <p className="text-sm">
                Looking for a referral to Meta, Google and Amazon
              </p>
              <p className="text-xs">
                1. My Background: I am graduating from UC Berkeley this May with
                a degree in Data Science...
              </p>
              <div className="flex flex-wrap gap-2">
                <Badge variant="secondary">
                  <img
                    alt="meta"
                    className="h-2"
                    src="/images/companies/meta.svg"
                  />
                </Badge>
                <Badge variant="secondary">
                  <img
                    alt="google"
                    className="h-3"
                    src="/images/companies/google.svg"
                  />
                </Badge>
                <Badge variant="secondary">
                  <img
                    alt="amazon"
                    className="h-3"
                    src="/images/companies/amazon.svg"
                  />
                </Badge>
              </div>
            </CardContent>
          </Card>
          <Card className="w-full">
            <CardContent className="space-y-2">
              <p className="text-sm">
                Looking for internship referral at Microsoft
              </p>
              <p className="text-xs">
                1. My Background: 3d CS Student at a well known public school in
                California Demonstrated...
              </p>
              <Badge variant="secondary">
                <img
                  alt="microsoft"
                  className="h-3"
                  src="/images/companies/microsoft.svg"
                />
              </Badge>
            </CardContent>
          </Card>
        </div>
        <Link
          className="block mt-4 text-sm text-center text-primary-600"
          href="#"
        >
          Show all (+700)
        </Link>
      </Section>

      <Section>
        <h2 className="text-3xl font-bold text-center">
          How can we help further your career?
        </h2>
        <Link className="font-bold" href={ROUTES.SIGNUP.href}>
          <Button className="mt-6 mx-auto block">Get Started</Button>
        </Link>
      </Section>

      <Footer className="py-12" />

      <ReferralWidget />
    </>
  )
}

function StarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
    </svg>
  )
}
