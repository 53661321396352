'use client'
import { Button } from '@/components/ui/button'
import {
  SelectValue,
  SelectTrigger,
  SelectItem,
  SelectContent,
  Select,
} from '@/components/ui/select'
import Link from 'next/link'
import ROUTES from '../app/routes'
import { HomeCompanyFragment, useHomeCompaniesQuery } from '@/generated/graphql'
import Combobox from './ui/combobox'
import { companiesToOptions } from '@/lib/ui'
import { useState } from 'react'

export default function HomeHero() {
  const { data: companiesData } = useHomeCompaniesQuery()
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('')

  const fallbackCompanies: Omit<HomeCompanyFragment, '__typename'>[] = [
    { id: '0', name: 'Apple' },
    { id: '1', name: 'Amazon' },
    { id: '2', name: 'Microsoft' },
    { id: '3', name: 'Google' },
    { id: '4', name: 'Meta' },
  ]

  const companies = companiesData?.companies ?? fallbackCompanies

  return (
    <section className="flex flex-col items-center pt-36 pb-12 px-8">
      <h1 className="text-4xl font-bold text-center">
        Get referrals to top tech companies
      </h1>
      <p className="mt-4 text-center text-md px-2">
        Which company would you like a referral to?
      </p>
      <div className="mt-4 w-full max-w-xs">
        <Combobox
          options={companiesToOptions(companies)}
          value={selectedCompanyId}
          onChange={(companyId) => setSelectedCompanyId(companyId)}
          thing='company'
        />
        <Button className="mt-4 w-full" asChild>
          <Link href={ROUTES.SIGNUP.href}>Continue</Link>
        </Button>
      </div>
      <p className="mt-6 text-xs text-center text-secondary-foreground px-1">
        Earn up to $2,294/month by giving advice on CareerParty. {` `}
        <Link className="font-bold" href={ROUTES.SIGNUP.href}>
          Start Earning
        </Link>
      </p>
    </section>
  )
}
